$(document).ready(function(){

    // navi burger mobile
    $('.navibutton').click(function() {
        $(this).toggleClass("open");
        $('.container-navigation').toggleClass("open");
        $('body').toggleClass("overflow-hidden");
    });

    // Klick auf eien Link, der eine Raute (# = Anker) enthält
    $('a[href^="#"]').on('click',function(e) {
        e.preventDefault();

        var target = this.hash;
        var $target = $(target);

        $('html, body').stop().animate({
            'scrollTop': $target.offset().top
        }, 500, 'swing', function () {
            window.location.hash = target;
        });
    });


    // back-to-top
    $(window).on('scroll', function() {
        if ($(window).scrollTop() > 300) {
            $(".back-to-top").show();
        } else {
            $(".back-to-top").hide();
        }

        if ($(window).scrollTop() > $( window ).height()-90) {
            $(".body-homepage header").addClass('active');
        } else {
            $(".body-homepage header").removeClass('active');
        }
    });

    // hover arbeiten
    $('.arbeitWrapper').hover(function() {
        var dataId = $(this).data("id");
        $('.arbeitText-' + dataId).toggleClass("d-flex");
    });

    // hover logo
    $('.logoWrapper').hover(function() {
        $(this).toggleClass('active');
    });

    // showAllClients
    $('.showAllClients').click(function() {
        $('.row-kundenWrapper .col').removeClass('d-none');
        $('.row-showAllClients').hide();
    });

    // navi burger mobile
    $('.pofcatFilter li').click(function() {
        var dataId = $(this).data("id");

        if (dataId == 0) {
            $('.pofcat').removeClass('d-none');
            $('.pofcat').show();
        } else {
            $('.pofcat').removeClass('d-none');
            $('.pofcat').hide();
            $('.pofcat' + dataId).show();
        }
        $('.pofcatFilter li').removeClass('active');
        $(this).addClass('active');

        var counter = 1;
        $( ".row-ani .col" ).each(function( index ) {
            isOnScreen( $( this ), counter );
            counter++;
        });

    });

    function isOnScreen(elem, counter) {
        if ($(elem).visible(true)) {
            if ($(elem).hasClass('active')) {
            } else {
                // The element is visible, do something
                $(elem).addClass('active');
                $(elem).addClass('active'+counter);
            }
        }
    }

    var counter = 1;
    $( ".row-ani .col" ).each(function( index ) {
        isOnScreen( $( this ), counter );
        counter++;
    });

    window.addEventListener('scroll', function(e) {
        var counter = 1;
        $( ".row-ani .col" ).each(function( index ) {
            if ($(this).hasClass('active')) {
            } else {
                // The element is visible, do something
                isOnScreen( $( this ), counter );
                counter++;
            }
        });
    });

/*

  // blognavi mobiel
  if (window.matchMedia("(max-width: 899px)").matches) {

  }

*/

});